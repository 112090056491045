// Init Photoswipe.js
// es6
//
// uses:
// - https://github.com/dimsemenov/photoswipe
// - https://photoswipe.com/getting-started/

const options = {
  gallery: 'body',
  children: '.photoswipe',
  pswpModule: () => import(window.APU.photoswipe.module),
};
let lightbox;
let instance;

function getLightbox() {
  if (!lightbox) {
    lightbox = import(window.APU.photoswipe.lightbox).then(
      (module) => module.default
    );
  }
  return lightbox;
}

function initPhotoswipe() {
  if (
    typeof window.APU === 'undefined' ||
    window.APU === null ||
    !window.APU.photoswipe
  ) {
    return;
  }

  if (
    window.APU.photoswipe.module &&
    window.APU.photoswipe.lightbox &&
    document.querySelector(options.gallery)
  ) {
    getLightbox()
      .then((lightbox) => {
        if (instance) {
          instance.destroy();
        }
        instance = new lightbox(options);
        return instance;
      })
      .then(addCaptions)
      .then((instance) => {
        instance.init();
      });
  }
}

function addCaptions(lightbox) {
  lightbox.on('uiRegister', function () {
    lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: 'Caption text',
      onInit: (el, pswp) => {
        lightbox.pswp.on('change', () => {
          const currSlideElement = lightbox.pswp.currSlide.data.element;
          let captionHTML = '';
          if (currSlideElement) {
            const closestLi = currSlideElement.closest('li');
            if (closestLi) {
              const caption = closestLi.querySelector('.slide-caption');
              if (caption) {
                // get caption from element with class slide-caption
                captionHTML = caption.innerHTML;
                el.classList.remove('hidden');
              } else {
                el.classList.add('hidden');
              }
            }
          }
          el.innerHTML = captionHTML || '';
        });
      },
    });
  });
  return lightbox;
}

initPhotoswipe();
document.addEventListener('pjax:success', initPhotoswipe);
